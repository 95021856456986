@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table; }

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%; }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

.sidenav-descr {
  font-size: 30px;
  background: #263238;
  color: #fff; }

.card-image {
  position: relative;
  width: 100%;
  height: auto; }

.card-image .btn-floating {
  position: absolute;
  top: 18px;
  right: -15px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); }

.card-image .btn-floating-bottom {
  position: absolute;
  top: 18px;
  left: 18px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: white;
  border-radius: 50%;
  width: 32.5px;
  display: flex;
  height: 32.5px;
  text-align: -webkit-center;
  justify-content: center;
  align-items: center; }

.pad {
  padding-left: 220px;
  padding-top: 10px; }

@media only screen and (max-width: 992px) {
  .pad {
    padding-left: 15px; } }

@media only screen and (max-width: 992px) {
  .content-hide {
    display: none; } }

div.polaroid {
  width: 25%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

img {
  width: 100%; }

div.container {
  text-align: center;
  padding: 10px 20px; }

.text-hide-me {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis; }

.desc-text {
  font-family: 'Droid Sans', sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: justify; }

.card-title {
  margin-left: 3px;
  bottom: 0;
  padding: 10px; }

.result {
  display: inline-block;
  margin: 30px;
  text-align: left;
  cursor: pointer; }

.boxInner {
  overflow: hidden; }

.boxInner img:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05); }

.modal-overlay {
  position: fixed;
  z-index: 999;
  top: -25%;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: #000;
  display: none;
  will-change: opacity; }

.result-details {
  background-color: black;
  color: white;
  opacity: 0.55;
  width: 15%;
  margin-left: 0px;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding: 10px; }

.result-details-movie {
  background-color: black;
  color: white;
  opacity: 0.55;
  width: 100%;
  max-height: 50%;
  margin-left: 0px;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding: 10px; }

#loader-wrapper {
  z-index: 1000; }

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  margin: -50px 0 0 50px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3498db;
  border-left-color: #3498db;
  -webkit-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */ }

#loader:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;
  border-left-color: #f9c922;
  -webkit-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */ }

/*#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;
  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera
}*/
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */ }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */ } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */ }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */ } }

@media only screen and (min-width: 993px) {
  .sidenav {
    z-index: 997; }
  .drag-target {
    z-index: 994; }
  .sidenav-overlay {
    z-index: 997; }
  .sidenav-overlay.sidenav-descr {
    z-index: 991; }
  .drag-target.sidenav-descr {
    z-index: 991; }
  .sidenav.sidenav-descr {
    z-index: 999; } }

@media only screen and (max-width: 600px) {
  #loader {
    margin: -43px 0 0 50px; } }

.hideme {
  display: none; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

a.margin-for-button {
  margin-top: 40px;
  /*desired value*/ }
